<template>
  <v-card>
    <v-card-title>
      <div>
        <div class="headline">Transfer</div>
        <div class="subtitle-1 light-grey">
          Cash overview and manual commission transfer
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <v-btn
        x-small
        outlined
        color="primary"
        class="mb-3"
        @click="showFilters = !showFilters"
        >Filters</v-btn
      >
      <br />

      <div v-if="showFilters" class="mb-3" style="max-width: 300px">
        <v-text-field
          v-model="filters.min_possible_amount"
          label="Min possible amount"
          hint="Minimum possible amount for influencer"
          color="primary"
          type="number"
          outlined
          persistent-hint
          small
          dense
          clearable
        ></v-text-field>
        <v-text-field
          v-model="filters.max_possible_amount"
          label="Max possible amount"
          hint="Maximum possible amount for influencer"
          color="primary"
          type="number"
          outlined
          persistent-hint
          small
          dense
          clearable
        ></v-text-field>

        <date-picker
          v-model="filters.end_date"
          label="Date"
          hint="Searches transactions generated before this date"
          :persistent-hint="true"
          outlined
        ></date-picker>

        <business-autocomplete
          v-model="filters.business_uuid"
          :return-object="false"
          class="filter-select"
          outlined
        ></business-autocomplete>

        <v-btn color="primary" small class="float-right" @click="loadStatuses">
          Search
        </v-btn>

        <div style="clear: both"></div>
      </div>

      <v-container fluid grid-list-md class="p-0 transfer-widgets mb-4">
        <v-layout wrap>
          <v-flex sm3>
            <stat-widget
              icon="fal fa-coins"
              :title="totalCommissionStats.status_new | local_numbers"
              subtitle="New"
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex sm3>
            <stat-widget
              icon="fal fa-coins"
              :title="totalCommissionStats.status_approved | local_numbers"
              subtitle="Approved"
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex sm3>
            <stat-widget
              icon="fal fa-coins"
              :title="totalCommissionStats.status_invoiced | local_numbers"
              subtitle="Invoiced"
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex sm3>
            <stat-widget
              icon="fal fa-coins"
              :title="totalCommissionStats.status_paid | local_numbers"
              subtitle="Total debt"
              bordered
            ></stat-widget>
          </v-flex>

          <v-flex sm3>
            <stat-widget
              icon="fal fa-coins"
              :title="
                totalCommissionStats.status_transfer_ready_total | local_numbers
              "
              subtitle="Transfer ready"
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex sm3>
            <stat-widget
              icon="fal fa-coins"
              :title="
                totalCommissionStats.status_transfer_possible_total
                  | local_numbers
              "
              subtitle="Transfer possible"
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex sm3>
            <stat-widget
              icon="fal fa-coins"
              :title="
                totalCommissionStats.stripe_balance_available | local_numbers
              "
              subtitle="Stripe available"
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex sm3>
            <stat-widget
              icon="fal fa-coins"
              :title="totalCommissionStats.legacy_invoiced | local_numbers"
              subtitle="Legacy Invoiced"
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex sm3>
            <stat-widget
              icon="fal fa-coins"
              :title="totalCommissionStats.legacy_paid | local_numbers"
              subtitle="Legacy Paid"
              bordered
            ></stat-widget>
          </v-flex>
        </v-layout>
      </v-container>

      <div
        v-if="
          items.length > 0 &&
            totalCommissionStats.status_transfer_possible_total <=
              totalCommissionStats.stripe_balance_available
        "
      >
        <v-checkbox
          v-model="isTransferAllApproved"
          :label="
            'Transfer ' +
              $options.filters.local_numbers(
                totalCommissionStats.status_transfer_possible_total
              ) +
              ',- to a total of ' +
              itemsForTransfer.length +
              ' influencers'
          "
        ></v-checkbox>

        <v-btn
          color="primary"
          :disabled="!isTransferAllApproved || isTransferLoading"
          @click="transferToAll()"
        >
          {{ transferButtonText }}
        </v-btn>

        <div v-if="itemsSkipped" class="mt-2">
          Skipped {{ itemsSkipped }} influencers due to low balance
        </div>
      </div>
      <div v-else>
        Not possible to transfer. Either low stripe cash, or no influencers.
      </div>
      <br />

      <influencer-transfer-data-table
        :items="items"
        :loading="loading"
      ></influencer-transfer-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import local_numbers from "../../../helpers/filters/local_numbers";
import BusinessAutocomplete from "../../autocompletes/BusinessAutocomplete";
import DatePicker from "../../common/filters/DatePicker";
import StatWidget from "../../widgets/StatWidget";
import InfluencerTransferDataTable from "../data-tables/InfluencerTransferDataTable";

export default {
  components: {
    DatePicker,
    StatWidget,
    InfluencerTransferDataTable,
    BusinessAutocomplete,
  },
  filters: {
    local_numbers,
  },
  data: () => ({
    totalCommissionStats: {},
    items: [],
    loading: true,
    showFilters: false,
    filters: {},
    isTransferAllApproved: false,
    isTransferLoading: false,
    itemsRecentlyTransferred: 0,
    itemsSkipped: 0,
  }),
  computed: {
    totalGearing() {
      return (
        this.totalCommissionStats.stripe_balance_available -
        this.totalCommissionStats.status_transfer_possible_total
      );
    },
    itemsForTransfer() {
      return this.items.filter((item) => item.status_transfer_possible_total > 0);
    },
    transferButtonText() {
      if (this.isTransferLoading) {
        return (
          "Transferring... " +
          (this.itemsForTransfer.length - this.itemsRecentlyTransferred) +
          " left.."
        );
      }

      return "Stripe Transfer to all";
    },
  },
  watch: {
    showFilters(newValue) {
      // Set default filter
      if (newValue && !this.filters.min_possible_amount) {
        this.filters.min_possible_amount = 200;
      }
    },
  },
  methods: {
    ...mapActions("core", [
      "getInfluencerCommissionStatuses",
      "transferCommission",
    ]),
    loadStatuses() {
      this.loading = true;
      this.isTransferAllApproved = false;
      this.totalCommissionStats = {};
      this.items = [];

      let filters = this.cloneDeep(this.filters);

      if (filters.min_possible_amount) {
        filters.min_possible_amount = parseInt(filters.min_possible_amount);
      } else {
        delete filters.min_possible_amount;
      }

      if (filters.max_possible_amount) {
        filters.max_possible_amount = parseInt(filters.max_possible_amount);
      } else {
        delete filters.max_possible_amount;
      }

      this.getInfluencerCommissionStatuses({ ...filters, group: false }).then(
        (response) => {
          this.totalCommissionStats =
            response.data.length > 0 ? response.data[0] : {};
          this.totalCommissionStats.stripe_balance_available =
            response.stripe_balance_available;
        }
      );

      this.getInfluencerCommissionStatuses({ ...filters, group: true }).then(
        (response) => {
          this.items = response.data;
          this.loading = false;
        }
      );
    },
    async transferToAll() {
      this.itemsRecentlyTransferred = 0;
      this.itemsSkipped = 0;
      this.isTransferLoading = true;

      for (let i = 0; i < this.itemsForTransfer.length; i++) {
        let item = this.itemsForTransfer[i];

        if (item.status_transfer_possible_total > item.balance) {
          this.itemsRecentlyTransferred++;
          this.itemsSkipped++;
          continue;
        }

        if (i % 5 == 0) {
          await new Promise((r) => setTimeout(r, 2000));
        }

        let params = {
          influencer_id: item.influencer_id,
        };

        if (this.filters.end_date) {
          params.end_date = this.filters.end_date;
        }

        this.transferCommission(params).then(() => {
          this.itemsRecentlyTransferred++;
        });

        await new Promise((r) => setTimeout(r, 100));
      }

      await new Promise((r) => setTimeout(r, 2500));

      this.loadStatuses();

      this.isTransferLoading = false;
    },
  },
  created() {
    this.loadStatuses();
  },
};
</script>

<style lang="scss">
div.transfer-widgets {
  .v-application {
    div.display-1 {
      font-size: 1.7rem !important;
    }
  }
}
</style>
