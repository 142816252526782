<template>
  <div class="businesess-table-wrapper">
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :items-per-page="items.length"
      hide-default-footer
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      ></v-progress-linear>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.influencer_id">
            <td>
              <router-link
                :to="{ name: 'influencer', params: { id: item.influencer_id } }"
              >
                {{ item.influencer_name }}
              </router-link>
            </td>
            <td>
              <small
                >Type:
                {{
                  item.account_type
                    ? item.account_type === 1
                      ? "Business"
                      : "Personal"
                    : "-"
                }}</small
              >
              <br />
              <small>Taxable: {{ item.is_taxable ? "✓" : "x" }}</small>
            </td>
            <td class="word-no-wrap">
              <small
                >Transfers: {{ item.transfers_enabled_at ? "✓" : "x" }}</small
              >
              <br />
              <small>Payouts: {{ item.payouts_enabled_at ? "✓" : "x" }}</small>
            </td>
            <td>
              {{ item.status_new | local_numbers }}
            </td>
            <td>
              {{ item.status_approved | local_numbers }}
            </td>
            <td>
              {{ item.status_invoiced | local_numbers }}
            </td>
            <td>
              {{ item.status_paid | local_numbers }}
            </td>
            <td>
              {{ item.status_transfer_ready_total | local_numbers }}
            </td>
            <td>
              {{ item.status_transfer_possible_total | local_numbers }}
            </td>
            <td>
              {{ item.balance | local_numbers }}
            </td>
            <td>
              {{ item.legacy_invoiced | local_numbers }}
            </td>
            <td>
              {{ item.legacy_paid | local_numbers }}
            </td>
            <td>
              <v-btn
                small
                color="primary"
                :disabled="isTransferDisabled(item)"
                @click="selectedInfluencer = item"
              >
                Transfer
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <v-dialog v-if="showDialog" v-model="showDialog" max-width="800px">
      <v-card>
        <v-card-title>
          {{ selectedInfluencer.influencer_name }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="transactionAmountSelection"
                label="Amount"
                hint="Selects anything below typed amount"
                color="primary"
                outlined
                small
                dense
              ></v-text-field>
            </v-col>
            <v-col>
              <date-picker
                v-model="transactionDateSelection"
                label="Date"
                placeholder="Selects anything before this date"
                outlined
              ></date-picker>
            </v-col>
            <v-col>
              <business-autocomplete
                v-model="businessUuid"
                :return-object="false"
                class="filter-select"
                outlined
              ></business-autocomplete>
            </v-col>
          </v-row>

          <v-btn
            color="primary"
            class="mr-2"
            outlined
            small
            :disabled="
              transactionAmountSelection <= 0 &&
                !transactionDateSelection &&
                !businessUuid
            "
            @click="selectTransactions()"
          >
            Select
          </v-btn>
          <v-btn
            color="primary"
            class="mr-2"
            outlined
            small
            :disabled="selectedTransactions.length === 0"
            @click="selectedTransactions = []"
          >
            Clear
          </v-btn>
          <br />

          <div
            id="virtual-scroll-table"
            class="overflow-auto mt-2"
            style="max-height: 250px"
          >
            <v-data-table
              v-model="selectedTransactions"
              :headers="transactionHeaders"
              :items="transactions.data"
              :loading="loadingTransactions"
              :items-per-page="transactions.total"
              hide-default-footer
              v-scroll:#virtual-scroll-table="onScroll"
              dense
            >
              <v-progress-linear
                slot="progress"
                color="primary"
                indeterminate
              ></v-progress-linear>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.uuid">
                    <td style="max-width: 36px">
                      <v-checkbox
                        v-model="selectedTransactions"
                        :value="item"
                      ></v-checkbox>
                    </td>
                    <td>
                      {{ (item.total_amount / 100) | local_numbers }}
                    </td>
                    <td>
                      {{
                        item.business
                          ? item.business.display_name
                          : "Make Influence"
                      }}
                    </td>
                    <td>
                      {{ item.date }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-checkbox
            v-if="selectedTransactions.length === 0"
            v-model="selectAllTransactions"
            label="Transfer all?"
            small
            class="mr-4 mt-3"
          ></v-checkbox>
          <i v-if="selectedTransactions.length !== 0" class="mr-2">
            {{ selectedTransactions.length }} selected
          </i>
          <v-btn
            color="primary"
            :disabled="
              (selectedTransactions.length === 0 && !selectAllTransactions) || isStripeTransferDisabled
            "
            :loading="transferLoading"
            @click="transferCommissionClick(false)"
          >
            Stripe Transfer {{ selectedTransactionAmount }},-
          </v-btn>
          <v-btn
            color="error"
            :disabled="
              selectedTransactions.length === 0 && !selectAllTransactions
            "
            :loading="transferLoading"
            @click="showConfirmManualTransfer = true"
          >
            Manual Transfer {{ selectedTransactionAmount }},-
          </v-btn>
        </v-card-actions>

        <confirm-dialog
          v-model="showConfirmManualTransfer"
          text="Are you sure you want to make manual transfer?"
          @confirm="transferCommissionClick(true)"
          btnText="Yes"
          btnCancelText="No"
        ></confirm-dialog>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import local_numbers from "../../../helpers/filters/local_numbers";
import BusinessAutocomplete from "../../autocompletes/BusinessAutocomplete";
import DatePicker from "../../common/filters/DatePicker";
import ConfirmDialog from "../../dialogs/ConfirmDialog";

export default {
  components: {
    DatePicker,
    ConfirmDialog,
    BusinessAutocomplete,
  },
  filters: {
    local_numbers,
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    headers: [
      { text: "Influencer", value: "influencer_name", sortable: true },
      { text: "Type", value: "display_name", sortable: false },
      { text: "Permissions", value: "display_name", sortable: false },
      { text: "New", value: "status_new", sortable: true },
      { text: "Approved", value: "status_approved", sortable: true },
      { text: "Invoiced", value: "status_invoiced", sortable: true },
      { text: "Paid", value: "status_paid", sortable: true },
      {
        text: "Total ready",
        value: "status_transfer_ready_total",
        sortable: true,
      },
      {
        text: "Total possible",
        value: "status_transfer_possible_total",
        sortable: true,
      },
      { text: "Current balance", value: "balance", sortable: true },
      { text: "Legacy Invoiced", value: "legacy_invoiced", sortable: true },
      { text: "Legacy Paid", value: "legacy_paid", sortable: true },
      { text: "Action", value: "webshop_system", sortable: false },
    ],
    loadingTransactions: true,
    selectedInfluencer: null,
    transactions: {
      data: [],
    },
    selectedTransactions: [],
    selectAllTransactions: false,
    transactionAmountSelection: "",
    transactionDateSelection: "",
    businessUuid: null,
    transferLoading: false,

    // Virtual scroller
    start: 0,
    timeout: null,
    rowHeight: 24,
    perPage: 25,
    showConfirmManualTransfer: false,
  }),
  computed: {
    showDialog: {
      get() {
        return !!this.selectedInfluencer;
      },
      set() {
        this.selectedInfluencer = null;
        this.selectAllTransactions = false;
      },
    },
    transactionHeaders() {
      return [
        { sortable: false },
        { text: "Amount", value: "amount", sortable: true },
        { text: "Business", value: "business.display_name", sortable: true },
        { text: "Date", value: "date", sortable: true },
      ];
    },
    // TODO: Fix rounding issue
    // There's a bug where the cents are different from the loop and the status_transfer_ready_total
    selectedTransactionAmount() {
      if (this.selectAllTransactions) {
        return this.selectedInfluencer.status_transfer_ready_total;
      }

      let total = 0;

      for (let i = 0; i < this.selectedTransactions.length; i++) {
        let transaction = this.selectedTransactions[i];
        total += transaction.total_amount;
      }

      return total / 100;
    },
    isStripeTransferDisabled() {
      return !(
        (this.selectedInfluencer.transfers_enabled_at && this.selectedInfluencer.status_transfer_possible_total > 0)
      );
    },
  },
  methods: {
    ...mapActions("core", ["transferCommission", "manualTransferCommission", "getTransactions"]),
    _getTransactions() {
      this.transactions = [];
      this.selectedTransactions = [];

      if (!this.selectedInfluencer) {
        return;
      }

      this.loadingTransactions = true;

      let params = {
        influencer_id: this.selectedInfluencer.influencer_id,
        is_transfer_ready: true,
        limit: 500,
      };

      this.getTransactions(params).then((response) => {
        this.loadingTransactions = false;
        this.transactions = response;
      });
    },
    transferCommissionClick(isManual) {
      this.showConfirmManualTransfer = false;
      let influencerId = this.selectedInfluencer.influencer_id;
      this.transferLoading = true;

      let params = {
        influencer_id: influencerId,
      };

      // If single transactions
      if (!this.selectAllTransactions) {
        let transactionIds = [];

        for (let i = 0; i < this.selectedTransactions.length; i++) {
          transactionIds.push(this.selectedTransactions[i].uuid);
        }

        params["transaction_ids"] = transactionIds;
      }

      const transferMethod = (isManual ? this.manualTransferCommission : this.transferCommission);
      const resultKey = (isManual ? 'manualTransferInfluencerCommission' : 'transferInfluencerCommission');
      transferMethod(params)
        .then((response) => {
          this.transferLoading = false;

          if (response.data[resultKey].success) {
            this.setSnackSuccess("Success");

            let transferredAmount = this.selectedTransactionAmount;

            // Deduct the transferred amount from the row
            let index = this.items.findIndex(
              (item) =>
                item.influencer_id === this.selectedInfluencer.influencer_id
            );
            this.items[index].status_transfer_ready_total -= transferredAmount;
            
            if (this.selectedInfluencer.status_transfer_possible_total > 0) {
              this.items[
                index
              ].status_transfer_possible_total -= transferredAmount;
            }
            this.items[index].status_paid -= transferredAmount;
            this.items[index].balance -= transferredAmount;

            // If single transaction transfer
            if (!this.selectAllTransactions) {
              this._getTransactions();
            } else {
              this.showDialog = false;
            }
          } else {
            this.setSnackError("Something went wrong");
          }
        })
        .catch((err) => {
          console.error(err);
          this.transferLoading = false;
          this.setSnackError("Something went wrong");
        });
    },
    isTransferDisabled(item) {
      return !item.status_transfer_ready_total;
    },
    selectTransactions() {
      let selectedItems = this.cloneDeep(this.transactions.data);

      if (this.transactionAmountSelection > 0) {
        let amount = this.transactionAmountSelection * 100;

        selectedItems = selectedItems.filter((item) => {
          return item.total_amount <= amount;
        });
      }

      if (this.transactionDateSelection) {
        let selectedDate = this.$moment(this.transactionDateSelection).add(
          1,
          "days"
        );

        selectedItems = selectedItems.filter((item) => {
          let itemDate = this.$moment(item.date);
          return itemDate.isBefore(selectedDate);
        });
      }

      if (this.businessUuid) {
        selectedItems = selectedItems.filter((item) => {
          return item.business.uuid === this.businessUuid;
        });
      }

      // Set the transactions to the items
      this.selectedTransactions = selectedItems;

      // Stupid hack to force update the UI
      setTimeout(() => {
        this.selectedTransactions = selectedItems;
      }, 250);
    },
    onScroll(e) {
      // debounce if scrolling fast
      this.timeout && clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        const { scrollTop } = e.target;
        const rows = Math.ceil(scrollTop / this.rowHeight);

        this.start =
          rows + this.perPage > this.transactions.data.length
            ? this.transactions.data.length - this.perPage
            : rows;

        this.$nextTick(() => {
          e.target.scrollTop = scrollTop;
        });
      }, 20);
    },
  },
  watch: {
    selectedInfluencer() {
      this._getTransactions();
    },
  },
};
</script>
